import React, { Fragment, useContext, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { AuthActions, AuthContext } from "../context/auth";
import styles from "./index.module.scss";
import { useIntercom } from "react-use-intercom";

import libraryIcon from "../../../assets/images/library_icon.svg";
import callTimerIcon from "../../../assets/images/call_timer_icon.svg";
import statsTrackerIcon from "../../../assets/images/stats_tracker_icon.svg";
import resourcesIcon from "../../../assets/images/resources_icon.svg";
import helpIcon from "../../../assets/images/help_icon.svg";
import supportIcon from "../../../assets/images/support_icon.svg";
import logOutIcon from "../../../assets/images/log_out_icon.svg";
import facebookIcon from "../../../assets/images/facebook_icon.svg";
import twitterIcon from "../../../assets/images/twitter_icon.svg";
import youtubeIcon from "../../../assets/images/youtube_icon.svg";

const RightSidebar = ({ openState, onClose }: any) => {
    const [open, setOpen] = useState(openState);
    const { dispatch } = useContext(AuthContext);
    const { update, showMessages, shutdown } = useIntercom();

    const close = () => setOpen(false);

    useEffect(() => {
        setOpen(openState);
    }, [openState]);

    useEffect(() => {
        if (!open) {
            onClose();
        }
    }, [open]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 overflow-hidden z-50"
                onClose={setOpen}
            >
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-200"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-200"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="w-screen max-w-md">
                                <div className="h-full flex flex-col py-6 bg-jp-black-800 shadow-xl overflow-y-scroll">
                                    <div className="px-6 sm:px-6">
                                        <div className="flex items-start justify-between">
                                            <div className="h-7 flex items-center">
                                                <button
                                                    type="button"
                                                    className=" text-white hover:text-gray-500 focus:outline-none"
                                                    onClick={close}
                                                >
                                                    <span className="sr-only">
                                                        Close panel
                                                    </span>
                                                    <XIcon
                                                        className="h-6 w-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 relative flex-1 ">
                                        <div className="absolute inset-0 ">
                                            <div
                                                className="h-full flex flex-col justify-between"
                                                aria-hidden="true"
                                            >
                                                <div className="space-y-4">
                                                    {/* Library */}
                                                    <Link
                                                        to={"/"}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                        onClick={close}
                                                    >
                                                        <img
                                                            src={libraryIcon}
                                                            alt="Library"
                                                        />
                                                        Library
                                                    </Link>

                                                    {/* Call Timer */}
                                                    <Link
                                                        to={"#"}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                        onClick={() => {
                                                            window.open(
                                                                "/call-timer-widget",
                                                                "CallTimerWidget",
                                                                "menubar=0,resizable=0,width=470,height=600"
                                                            );
                                                        }}
                                                    >
                                                        <img
                                                            src={callTimerIcon}
                                                            alt="Call Timer"
                                                        />
                                                        Call Timer
                                                    </Link>

                                                    {/* Stats Tracker */}
                                                    <Link
                                                        to={"/stats-tracker"}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                        onClick={close}
                                                    >
                                                        <img
                                                            src={
                                                                statsTrackerIcon
                                                            }
                                                            alt="Stats Tracker"
                                                        />
                                                        Stats Tracker
                                                    </Link>

                                                    {/* Resources */}
                                                    <Link
                                                        to={"/resources"}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                        onClick={close}
                                                    >
                                                        <img
                                                            src={resourcesIcon}
                                                            alt="Resources"
                                                        />
                                                        Resources
                                                    </Link>

                                                    {/* Help */}
                                                    <a
                                                        href="https://help.joshphegan.com.au/en/articles/3582180-where-to-start-in-digital"
                                                        target="_blank"
                                                        rel="noopener"
                                                        onClick={close}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                    >
                                                        <img
                                                            src={helpIcon}
                                                            alt="Help"
                                                        />
                                                        Help
                                                    </a>

                                                    {/* Support */}
                                                    <button
                                                        onClick={() => {
                                                            // Show the icon
                                                            update({
                                                                hideDefaultLauncher:
                                                                    false,
                                                            });

                                                            // Show the messages
                                                            showMessages();

                                                            // Close the sidebar
                                                            close();
                                                        }}
                                                        className={
                                                            styles.sidebarItem
                                                        }
                                                    >
                                                        <img
                                                            src={supportIcon}
                                                            alt="Support"
                                                        />
                                                        Support
                                                    </button>

                                                    {/* Log Out */}
                                                    <div className="pt-32">
                                                        <Link
                                                            to={"/"}
                                                            className={`${styles.sidebarItem} uppercase`}
                                                            onClick={() => {
                                                                shutdown();
                                                                dispatch({
                                                                    action: AuthActions.LOGOUT,
                                                                    data: {},
                                                                });
                                                                close();
                                                            }}
                                                        >
                                                            <img
                                                                src={logOutIcon}
                                                                alt="Log Out"
                                                            />
                                                            Log out
                                                        </Link>
                                                    </div>
                                                </div>

                                                {/* Social Media Icons */}
                                                <div className="flex-row flex gap-6 px-6 py-12">
                                                    <div className="cursor-pointer">
                                                        <img
                                                            src={facebookIcon}
                                                            alt="Facebook"
                                                        />
                                                    </div>
                                                    <div className="cursor-pointer">
                                                        <img
                                                            src={twitterIcon}
                                                            alt="Twitter"
                                                        />
                                                    </div>
                                                    <div className="cursor-pointer">
                                                        <img
                                                            src={youtubeIcon}
                                                            alt="YouTube"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    );
};

export default RightSidebar;
