import React, { useContext, useEffect, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import Modal from "react-modal";
import styles from "./index.module.scss";
import {
    createTeam,
    deleteTeamMember,
    getAvailableRoles,
    getTeamMembers,
    sendInvitation,
    updateTeamMember,
    updateTeamName,
} from "../../core/services/team.service";
import { AuthContext } from "../../core/context/auth";
import { debounce, map, reverse } from "lodash";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getCurrentSubscription } from "../../core/services/subscription.service";
import Swal from "sweetalert2";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: 500,
        borderRadius: 0,
        background: "#fff",
        borderColor: "#161616",
    },
    overlay: {
        background: "rgba(0,0,0,0.70)",
    },
};
const TeamManagement = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const [createTeamModalIsOpen, setCreateTeamModalIsOpen] = useState(false);

    const [teamName, setTeamName] = useState("");

    const [invitationPayload, setInvitationPayload] = useState({
        first_name: "",
        last_name: "",
        email: "",
        role: "member",
        is_subscriber: true,
    });

    const { authState } = useContext(AuthContext);

    const [roles, setRoles] = useState([]);

    const [teamMembers, setTeamMembers] = useState<any>([]);

    const [originalTeamMembers, setOriginalTeamMembers] = useState<any>([]);

    const [currentSubscription, setCurrentSubscription] = useState<any>();

    const [isTeamEdit, setIsTeamEdit] = useState(false);

    const refreshList = () => {
        setTeamMembers([]);
        setOriginalTeamMembers([]);

        if (authState.user.relationships.team) {
            setTeamName(authState.user.relationships.team.attributes.name);
            getTeamMembers(authState.user.relationships.team.id).then(
                (resp) => {
                    const cleanData = resp.data.map((x: any) => {
                        return {
                            ...x.data,
                            attributes: {
                                ...x.data.attributes,
                                name: `${x.data.attributes.first_name} ${x.data.attributes.last_name}`,
                            },
                        };
                    });

                    if (
                        cleanData.find((x: any) => x.id !== authState.user.id)
                    ) {
                        cleanData.push({
                            id: authState.user.id,
                            attributes: {
                                email: authState.user.attributes.email,
                                first_name:
                                    authState.user.attributes.first_name,
                                is_subscriber:
                                    authState.user.relationships.team.attributes
                                        .is_subscriber,
                                last_name: authState.user.attributes.last_name,
                                name:
                                    authState.user.attributes.name ||
                                    authState.user.attributes.first_name +
                                        " " +
                                        authState.user.attributes.last_name,
                                role: authState.user.relationships.team
                                    .attributes.role,
                            },
                            type: "self",
                        });
                    }

                    console.log(
                        "🚀 ~ file: index.tsx ~ line 72 ~ refreshList ~ resp",
                        cleanData
                    );

                    setTeamMembers((currentState: any) => {
                        return [...currentState, ...cleanData];
                    });

                    setOriginalTeamMembers((currentState: any) => {
                        return [...currentState, ...cleanData];
                    });
                }
            );
        }
    };

    useEffect(() => {
        getAvailableRoles().then((resp) => {
            setRoles(map(resp.data, (value, prop) => ({ prop, value })) as any);
        });

        getCurrentSubscription().then((resp) => {
            setCurrentSubscription(resp.data);
        });

        refreshList();
    }, []);

    return (
        <>
            <div className={"space-y-4 text-gray-400"}>
                {authState.user.relationships.team ? (
                    <>
                        <div
                            className="text-2xl text-white hover:text-jp-blue cursor-pointer"
                            onClick={() => {
                                setCreateTeamModalIsOpen(true);
                                setIsTeamEdit(true);
                            }}
                        >
                            {teamName}
                        </div>
                        <div>
                            <span className="font-bold text-white">
                                {teamMembers.length}
                            </span>{" "}
                            <span>Team members</span>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    setModalIsOpen(true);
                                }}
                                className="py-1 px-3 bg-jp-blue text-white rounded bg-opacity-80 hover:bg-opacity-100 mb-2"
                            >
                                Add new team member
                            </button>

                            <div>
                                {currentSubscription?.quantity ===
                                teamMembers.filter(
                                    (x: any) => x.attributes.is_subscriber
                                ).length ? (
                                    <span className="text-red-500">
                                        There is no more seats on your plan.
                                    </span>
                                ) : (
                                    "Need more space on your plan!"
                                )}{" "}
                                <Link
                                    to={
                                        "/settings/subscription-info/update-quantity"
                                    }
                                    className="text-jp-blue"
                                >
                                    Add seats to your plan
                                </Link>
                            </div>
                        </div>

                        <div>
                            <div className={"mb-6 flex"}>
                                <div className="p-2 bg-jp-blue text-white rounded-l">
                                    <SearchIcon className={"w-6"} />
                                </div>
                                <input
                                    type="text"
                                    placeholder={"Search..."}
                                    className={
                                        "p-2 rounded-r  focus:outline-none  text-gray-700"
                                    }
                                    style={{
                                        width: 400,
                                    }}
                                    onChange={debounce((ev) => {
                                        const query = ev.target.value;

                                        if (query.length) {
                                            setTeamMembers(() => {
                                                return originalTeamMembers.filter(
                                                    (x: any) =>
                                                        x.attributes.name.includes(
                                                            query
                                                        )
                                                );
                                            });
                                        } else {
                                            setTeamMembers(originalTeamMembers);
                                        }
                                    }, 500)}
                                />
                            </div>
                        </div>

                        <div>
                            <table className={"w-full"}>
                                <thead>
                                    <tr className={"text-white"}>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Subscription</td>
                                        <td>Role</td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {reverse(teamMembers).map((member: any) => {
                                        return (
                                            <tr key={member.id}>
                                                <td>
                                                    {member.attributes.name}
                                                </td>
                                                <td>
                                                    {member.attributes.email}
                                                </td>
                                                <td>
                                                    {member.attributes
                                                        .is_subscriber
                                                        ? "Team"
                                                        : "Individual"}
                                                </td>
                                                <td className={"capitalize"}>
                                                    {member.type !== "self" ? (
                                                        <select
                                                            className="bg-transparent p-0"
                                                            onChange={(ev) => {
                                                                if (
                                                                    ev.target
                                                                        .value ===
                                                                    "DELETE"
                                                                ) {
                                                                    Swal.fire({
                                                                        text: "Are you you want to delete team member?",
                                                                        showCancelButton:
                                                                            true,
                                                                        confirmButtonText:
                                                                            "Delete",
                                                                        confirmButtonColor:
                                                                            "rgb(239, 68, 68)",
                                                                    }).then(
                                                                        (
                                                                            result
                                                                        ) => {
                                                                            if (
                                                                                result.isConfirmed
                                                                            ) {
                                                                                deleteTeamMember(
                                                                                    authState
                                                                                        .user
                                                                                        .relationships
                                                                                        .team
                                                                                        .id,
                                                                                    member.id
                                                                                ).then(
                                                                                    (
                                                                                        resp
                                                                                    ) => {
                                                                                        refreshList();
                                                                                        toast.success(
                                                                                            "Team member deleted successfully."
                                                                                        );
                                                                                    }
                                                                                );
                                                                            } else {
                                                                                refreshList();
                                                                            }
                                                                        }
                                                                    );
                                                                } else {
                                                                    updateTeamMember(
                                                                        authState
                                                                            .user
                                                                            .relationships
                                                                            .team
                                                                            .id,
                                                                        member.id,
                                                                        ev
                                                                            .target
                                                                            .value
                                                                    ).then(
                                                                        (
                                                                            resp
                                                                        ) => {
                                                                            toast.success(
                                                                                "Team member role updated successfully."
                                                                            );
                                                                        }
                                                                    );
                                                                }
                                                            }}
                                                            defaultValue={
                                                                member
                                                                    .attributes
                                                                    .role
                                                            }
                                                        >
                                                            {roles.map(
                                                                (role: any) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                role.prop
                                                                            }
                                                                            key={
                                                                                role.prop
                                                                            }
                                                                        >
                                                                            {
                                                                                role.value
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                            <option
                                                                value={"DELETE"}
                                                            >
                                                                Delete
                                                            </option>
                                                        </select>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>

                            {!teamMembers.length && (
                                <div>No team members available.</div>
                            )}
                        </div>
                    </>
                ) : (
                    <div>
                        <div
                            className="hover:text-jp-blue cursor-pointer"
                            onClick={() => setCreateTeamModalIsOpen(true)}
                        >
                            Create a team
                        </div>
                    </div>
                )}
            </div>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={() => {
                    console.log("Modal Open");
                }}
                onRequestClose={() => {
                    setModalIsOpen(false);
                }}
                style={customStyles}
            >
                <div className="space-y-4">
                    <div className={"text-2xl"}>Add new team member</div>
                    <div className="flex gap-4">
                        <input
                            type="text"
                            className="w-full py-1 px-2 outline-none border-b"
                            placeholder="First Name"
                            autoFocus
                            onChange={(ev) => {
                                setInvitationPayload((currentState) => {
                                    return {
                                        ...currentState,
                                        first_name: ev.target.value,
                                    };
                                });
                            }}
                        />

                        <input
                            type="text"
                            className="w-full py-1 px-2 outline-none border-b"
                            placeholder="Last Name"
                            onChange={(ev) => {
                                setInvitationPayload((currentState) => {
                                    return {
                                        ...currentState,
                                        last_name: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>
                        <input
                            type="email"
                            className="w-full py-1 px-2 outline-none border-b"
                            placeholder="Email"
                            onChange={(ev) => {
                                setInvitationPayload((currentState) => {
                                    return {
                                        ...currentState,
                                        email: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                    <div>
                        <label>
                            <input
                                defaultChecked={invitationPayload.is_subscriber}
                                type="checkbox"
                                placeholder="Subscriber"
                                onChange={(ev) => {
                                    setInvitationPayload((currentState) => {
                                        return {
                                            ...currentState,
                                            is_subscriber: ev.target.checked,
                                        };
                                    });
                                }}
                            />{" "}
                            Add user to my subscription
                        </label>
                    </div>
                    <div className={"flex gap-2 capitalize"}>
                        {roles.map((role: any) => {
                            return (
                                <div
                                    key={role.prop}
                                    className={
                                        invitationPayload.role === role.prop
                                            ? styles.activePill
                                            : styles.pill
                                    }
                                    onClick={() => {
                                        setInvitationPayload((currentState) => {
                                            return {
                                                ...currentState,
                                                role: role.prop,
                                            };
                                        });
                                    }}
                                >
                                    {role.value}
                                </div>
                            );
                        })}
                    </div>

                    <div>
                        <button
                            className={"w-full bg-jp-blue text-white py-2"}
                            onClick={() => {
                                sendInvitation(
                                    authState.user.relationships.team.id,
                                    invitationPayload
                                ).then((resp) => {
                                    if (!resp) {
                                        toast.error("No available seats in this team. Change the type to non-subscriber or increase the seats number.");
                                    } else {
                                        setModalIsOpen(false);

                                        toast.success(
                                            "Invitation sent successfully."
                                        );

                                        refreshList();
                                    }
                                });
                            }}
                        >
                            Apply
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={createTeamModalIsOpen}
                onRequestClose={() => {
                    setCreateTeamModalIsOpen(false);
                }}
                style={customStyles}
            >
                <div className="space-y-4">
                    <div className={"text-2xl"}>
                        {isTeamEdit ? "Edit team name" : "Add new team"}
                    </div>
                    <div className="flex gap-4">
                        <input
                            type="text"
                            className="w-full py-1 px-2 outline-none border-b"
                            placeholder="Team Name"
                            autoFocus
                            defaultValue={teamName}
                            onChange={(ev) => {
                                setTeamName(ev.target.value);
                            }}
                        />
                    </div>

                    <div>
                        <button
                            className={"w-full bg-jp-blue text-white py-2"}
                            onClick={() => {
                                if (isTeamEdit) {
                                    updateTeamName(
                                        authState.user.relationships.team.id,
                                        teamName
                                    ).then((resp) => {
                                        setCreateTeamModalIsOpen(false);
                                        toast.success(
                                            "Team name updated successfully."
                                        );
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 1000);
                                    });
                                } else {
                                    createTeam(teamName).then((resp) => {
                                        setCreateTeamModalIsOpen(false);
                                        toast.success(
                                            "Team created successfully."
                                        );
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 1000);
                                    });
                                }
                            }}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default TeamManagement;
