import React, { Fragment, useContext, useEffect, useState } from "react";
import menuIcon from "./../../../assets/images/menu.svg";
import userIcon from "./../../../assets/images/user.svg";
import videoIcon from "./../../../assets/images/video-blue.svg";
import audioIcon from "./../../../assets/images/audio-blue.svg";
import resourceIcon from "./../../../assets/images/resources-blue.svg";
import searchIcon from "./../../../assets/images/search.svg";
import { Transition } from "@headlessui/react";
import Autosuggest from "react-autosuggest";
import { searchAll } from "../services/search.service";
import { Menu } from "@headlessui/react";
import { AuthContext } from "../context/auth";
import { Link, useHistory } from "react-router-dom";
import { getCurrentSubscription } from "../services/subscription.service";
import { sum } from "lodash";

const toUpperCase = (string: string) =>
    string[0].toUpperCase() + string.slice(1);
const toUpperCaseAll = (string: string) => string.toUpperCase();

const TopMenu = ({ setOpen }: any) => {
    const history = useHistory();

    const { authState } = useContext(AuthContext);

    const [isSearchVisible, setIsSearchVisible] = useState(false);

    const [suggestions, setSuggestions] = useState<any>([]);

    const [value, setValue] = useState("");

    const [isMobile] = useState(window.innerWidth < 768);

    const getSuggestionValue = (suggestion: any) =>
        suggestion.text !== "No search results" ? suggestion.text : "";

    const onChange = (_: any, { newValue }: { newValue: string }) => {
        setValue(newValue);
    };

    const inputProps = {
        placeholder: "Search for anything...",
        value,
        onChange: onChange,
        className:
            "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm",
    };

    const getSuggestions = (value: string) => {
        const inputValue = value.trim().toLowerCase();
        return searchAll(inputValue, 10, null);
    };

    const renderSuggestion = (suggestion: any) => (
        <>
            {suggestion.text !== "No search results" ? (
                <div className={"mb-3 px-2"}>
                    <a
                        href={suggestion.url}
                        className={
                            "text-gray-600 flex gap-2 items-center hover:bg-gray-200"
                        }
                        target={suggestion.target}
                    >
                        <img
                            src={suggestion.icon}
                            alt="Album Type"
                            className={"float-left text-blue-600"}
                        />
                        <div className="text-sm">{suggestion.text}</div>
                    </a>
                </div>
            ) : (
                <div className="text-sm pointer-events-none italic text-gray-500 pt-2 pb-3 px-4">
                    {suggestion.text}
                </div>
            )}
        </>
    );

    const renderSectionTitle = (section: any) => (
        <div className={"text-gray-400 px-2 my-2"}>
            {toUpperCaseAll(section.title)}
        </div>
    );

    const renderSuggestionsContainer = ({
        containerProps,
        children,
        query,
    }: any) => {
        containerProps.className += " bg-white absolute z-10 rounded mt-2";
        return (
            <div
                {...containerProps}
                style={{ width: 300, maxHeight: 300, overflow: "auto" }}
            >
                {children}
            </div>
        );
    };

    const getSectionSuggestions = (section: any) => {
        return section.suggestions;
    };

    const onSuggestionsFetchRequested = ({ value }: any) => {
        getSuggestions(value)
            .then((resp) => {
                const suggestions = Object.entries<any[]>(resp.data)
                    .filter(
                        ([type]) => type === "albums" || type === "resources"
                    )
                    .map(([type, results]) => {
                        return {
                            title: toUpperCase(type),
                            suggestions: results.map((result) => {
                                let target = "_self";
                                const url =
                                    type === "resources"
                                        ? result.attributes.url
                                        : "/albums/" + result.id;

                                let icon = audioIcon;

                                if (type === "resources") {
                                    target = "_blank";
                                    icon = resourceIcon;
                                } else if (result.attributes.type === "video") {
                                    icon = videoIcon;
                                }

                                return {
                                    id: result.id,
                                    type,
                                    url,
                                    text: result.attributes.title,
                                    icon: icon,
                                    target: target,
                                };
                            }),
                        };
                    });

                const suggestionCount = sum(
                    suggestions.map((x) => x.suggestions.length)
                );

                if (suggestionCount) {
                    setSuggestions(suggestions);
                } else {
                    setSuggestions([
                        {
                            title: "",
                            suggestions: [{ text: "No search results" }],
                        },
                    ]);
                }
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    useEffect(() => {
        history.listen(() => {
            setIsSearchVisible(false);
        });
    });

    const validateSubscription = () => {
        if (
            history.location.pathname === "/settings/subscription-info" ||
            history.location.pathname === "/settings/user-info" ||
            history.location.pathname === "/auth"
        ) {
            return;
        }
        getCurrentSubscription().then((resp) => {
            if (!resp.data) {
                history?.push("/settings/subscription-info");
            }
        });
    };

    useEffect(() => {
        validateSubscription();

        return history.listen((location, action) => {
            validateSubscription();

            window.scrollTo({ top: 0 });
        });
    }, []);

    return (
        <div className={"flex gap-3 md:gap-6"}>
            <Transition show={isSearchVisible} as={Fragment}>
                <div style={{ width: 300 }}>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={
                            onSuggestionsFetchRequested
                        }
                        onSuggestionsClearRequested={
                            onSuggestionsClearRequested
                        }
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                        multiSection={true}
                        renderSectionTitle={renderSectionTitle}
                        getSectionSuggestions={getSectionSuggestions}
                        renderSuggestionsContainer={renderSuggestionsContainer}
                    />
                </div>
            </Transition>
            {isSearchVisible && isMobile ? null : (
                <>
                    <div
                        onClick={() => {
                            setIsSearchVisible(!isSearchVisible);
                        }}
                    >
                        <img
                            className="w-auto md:w-8 lg:w-10 cursor-pointer"
                            src={searchIcon}
                            alt="Search"
                        />
                    </div>
                    <Menu as="div" className="relative inline-block text-left">
                        <Menu.Button className="">
                            <img
                                src={userIcon}
                                alt="User"
                                className="w-auto md:w-8 lg:w-10"
                            />
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <Menu.Items className="z-10 absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div className="px-1 py-1 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-black text-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    {authState?.user?.attributes
                                        ?.is_admin ? null : (
                                        <>
                                            {authState.teamRole === "admin" ||
                                            authState.user?.relationships
                                                ?.team === null ? (
                                                <Menu.Item>
                                                    <Link
                                                        to={
                                                            "/settings/team-management"
                                                        }
                                                        className={
                                                            "block p-2 hover:bg-jp-black-600"
                                                        }
                                                    >
                                                        <svg
                                                            width="21"
                                                            height="21"
                                                            viewBox="0 0 21 21"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className={
                                                                "inline mr-2"
                                                            }
                                                        >
                                                            <path
                                                                d="M14.25 17.25V15.75C14.25 14.9544 13.9339 14.1913 13.3713 13.6287C12.8087 13.0661 12.0456 12.75 11.25 12.75H5.25C4.45435 12.75 3.69129 13.0661 3.12868 13.6287C2.56607 14.1913 2.25 14.9544 2.25 15.75V17.25M18.75 17.25V15.75C18.7495 15.0853 18.5283 14.4396 18.121 13.9142C17.7138 13.3889 17.1436 13.0137 16.5 12.8475M13.5 3.8475C14.1453 4.01273 14.7173 4.38803 15.1257 4.91423C15.5342 5.44044 15.7559 6.08762 15.7559 6.75375C15.7559 7.41988 15.5342 8.06706 15.1257 8.59327C14.7173 9.11947 14.1453 9.49477 13.5 9.66M11.25 6.75C11.25 8.40685 9.90685 9.75 8.25 9.75C6.59315 9.75 5.25 8.40685 5.25 6.75C5.25 5.09315 6.59315 3.75 8.25 3.75C9.90685 3.75 11.25 5.09315 11.25 6.75Z"
                                                                stroke="#fff"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                        Team Management
                                                    </Link>
                                                </Menu.Item>
                                            ) : null}
                                        </>
                                    )}
                                    {authState?.user?.relationships?.team
                                        ?.attributes?.can_upload_own_content &&
                                    authState.teamRole !== "member" ? (
                                        <Menu.Item>
                                            <Link
                                                to={
                                                    "/settings/content-management"
                                                }
                                                className={
                                                    "block p-2 hover:bg-jp-black-600"
                                                }
                                            >
                                                <svg
                                                    width="21"
                                                    height="21"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={"inline mr-2"}
                                                >
                                                    <path
                                                        d="M13.5 4.5H15C15.3978 4.5 15.7794 4.65804 16.0607 4.93934C16.342 5.22064 16.5 5.60218 16.5 6V16.5C16.5 16.8978 16.342 17.2794 16.0607 17.5607C15.7794 17.842 15.3978 18 15 18H6C5.60218 18 5.22064 17.842 4.93934 17.5607C4.65804 17.2794 4.5 16.8978 4.5 16.5V6C4.5 5.60218 4.65804 5.22064 4.93934 4.93934C5.22064 4.65804 5.60218 4.5 6 4.5H7.5M8.25 3H12.75C13.1642 3 13.5 3.33579 13.5 3.75V5.25C13.5 5.66421 13.1642 6 12.75 6H8.25C7.83579 6 7.5 5.66421 7.5 5.25V3.75C7.5 3.33579 7.83579 3 8.25 3Z"
                                                        stroke="#fff"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                Content Management
                                            </Link>
                                        </Menu.Item>
                                    ) : null}
                                    {authState?.user?.attributes
                                        ?.active_subscription ? (
                                        <Menu.Item>
                                            <Link
                                                to={
                                                    "/settings/subscription-info"
                                                }
                                                className={
                                                    "block p-2 hover:bg-jp-black-600"
                                                }
                                            >
                                                <svg
                                                    width="21"
                                                    height="21"
                                                    viewBox="0 0 21 21"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className={"inline mr-2"}
                                                >
                                                    <path
                                                        d="M15.75 7C15.75 5.60761 15.1969 4.27226 14.2123 3.28769C13.2277 2.30312 11.8924 1.75 10.5 1.75C9.10761 1.75 7.77226 2.30312 6.78769 3.28769C5.80312 4.27226 5.25 5.60761 5.25 7C5.25 13.125 2.625 14.875 2.625 14.875H18.375C18.375 14.875 15.75 13.125 15.75 7Z"
                                                        stroke="#fff"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                    <path
                                                        d="M12.0138 18.375C11.86 18.6402 11.6392 18.8603 11.3735 19.0133C11.1079 19.1664 10.8067 19.2469 10.5001 19.2469C10.1935 19.2469 9.89229 19.1664 9.62663 19.0133C9.36097 18.8603 9.14016 18.6402 8.98633 18.375"
                                                        stroke="#fff"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                                Subscription Info
                                            </Link>
                                        </Menu.Item>
                                    ) : null}
                                    <Menu.Item>
                                        <Link
                                            to={"/settings/user-info"}
                                            className={
                                                "block p-2 hover:bg-jp-black-600"
                                            }
                                        >
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className={"inline mr-2"}
                                            >
                                                <path
                                                    d="M17.5005 18.375V16.625C17.5005 15.6967 17.1317 14.8065 16.4754 14.1501C15.819 13.4937 14.9287 13.125 14.0005 13.125H7.00049C6.07223 13.125 5.18199 13.4937 4.52561 14.1501C3.86924 14.8065 3.50049 15.6967 3.50049 16.625V18.375"
                                                    stroke="#fff"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M10.4995 9.625C12.4325 9.625 13.9995 8.058 13.9995 6.125C13.9995 4.192 12.4325 2.625 10.4995 2.625C8.56652 2.625 6.99951 4.192 6.99951 6.125C6.99951 8.058 8.56652 9.625 10.4995 9.625Z"
                                                    stroke="#fff"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            User Info
                                        </Link>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <Link
                                            to={"/settings/analytics"}
                                            className={
                                                "block p-2 hover:bg-jp-black-600"
                                            }
                                        >
                                            <svg
                                                width="21"
                                                height="21"
                                                viewBox="0 0 21 21"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className={"inline mr-2"}
                                            >
                                                <path
                                                    d="M8.96875 3.71875C9.1428 3.71875 9.30972 3.78789 9.43279 3.91096C9.55586 4.03403 9.625 4.20095 9.625 4.375V11.375H16.625C16.7836 11.375 16.9368 11.4324 17.0563 11.5367C17.1758 11.6409 17.2536 11.7849 17.2751 11.942L17.2812 12.0312C17.2812 16.38 13.755 19.4688 9.40625 19.4688C7.31767 19.4688 5.31463 18.6391 3.83778 17.1622C2.36093 15.6854 1.53125 13.6823 1.53125 11.5938C1.53125 7.245 4.62 3.71875 8.96875 3.71875ZM8.3125 5.06363L8.12963 5.08462C4.90088 5.49587 2.84375 8.25388 2.84375 11.5938C2.84375 13.3342 3.53515 15.0034 4.76586 16.2341C5.99657 17.4648 7.66577 18.1562 9.40625 18.1562C12.7461 18.1562 15.5033 16.0982 15.9163 12.8704L15.9355 12.6875H8.96875C8.81017 12.6875 8.65695 12.6301 8.53744 12.5258C8.41792 12.4216 8.34019 12.2776 8.31862 12.1205L8.3125 12.0312V5.06363ZM11.5938 1.53125C13.6823 1.53125 15.6854 2.36093 17.1622 3.83778C18.6391 5.31463 19.4688 7.31767 19.4688 9.40625C19.4688 9.5803 19.3996 9.74722 19.2765 9.87029C19.1535 9.99336 18.9865 10.0625 18.8125 10.0625H11.5938C11.4197 10.0625 11.2528 9.99336 11.1297 9.87029C11.0066 9.74722 10.9375 9.5803 10.9375 9.40625V2.1875C10.9375 2.01345 11.0066 1.84653 11.1297 1.72346C11.2528 1.60039 11.4197 1.53125 11.5938 1.53125ZM12.25 2.87612V8.75H18.123L18.1029 8.56713C17.9169 7.12844 17.2597 5.79185 16.2339 4.76607C15.2082 3.7403 13.8716 3.08311 12.4329 2.89713L12.25 2.87612Z"
                                                    stroke="#fff"
                                                />
                                            </svg>
                                            Analytics
                                        </Link>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                    <a
                        href="#"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <img
                            className="w-auto md:w-8 lg:w-10"
                            src={menuIcon}
                            alt="Menu"
                        />
                    </a>
                </>
            )}
        </div>
    );
};

export default TopMenu;
