import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../core/context/auth";
import {
    getCurrentSubscription,
    updateSubscriptionQuantity,
} from "../../core/services/subscription.service";
import styles from "./index.module.scss";

const UpdateQuantity = () => {
    const { authState } = useContext(AuthContext);
    const history = useHistory();

    const [currentSubscription, setCurrentSubscription] = useState<any>();

    const [submitting, setSubmitting] = useState(false);

    const [quantity, setQuantity] = useState(0);

    const onSubmit = (ev: any) => {
        ev.preventDefault();
        setSubmitting(true);

        updateSubscriptionQuantity(
            currentSubscription.id,
            quantity,
            authState.user.relationships.team.id
        ).then((resp) => {
            toast.success("Subscription quantity updated.");

            refreshCurrentSubscription();
        });
    };

    const refreshCurrentSubscription = () => {
        getCurrentSubscription().then((resp) => {
            console.log(
                "🚀 ~ file: UpdateQuantity.tsx ~ line 34 ~ getCurrentSubscription ~ resp",
                resp
            );
            setCurrentSubscription(resp.data);

            setQuantity(resp.data.quantity);

            setSubmitting(false);
        });
    };

    useEffect(() => {
        refreshCurrentSubscription();
    }, []);

    return (
        <div className="container text-white py-16 ">
            <div className="w-2/4 space-y-8">
                {currentSubscription ? (
                    <>
                        <div className={styles.formLabel}>
                            Update your Digital plan
                        </div>
                        <div className="space-y-4">
                            <div>How many seats do you need?</div>
                            <div>
                                <input
                                    type="number"
                                    min={1}
                                    className="bg-transparent border border-white rounded py-1 pl-4 outline-none"
                                    autoFocus
                                    value={quantity}
                                    onChange={(ev) => {
                                        setQuantity(Number(ev.target.value));
                                    }}
                                />
                            </div>
                            <div className="text-sm text-gray-400">
                                You currently have{" "}
                                {currentSubscription.quantity} active people.
                            </div>
                        </div>

                        <div className="space-y-4">
                            <div className="">Plan summary</div>

                            <div className="flex justify-between text-gray-400 border-b pb-4 border-gray-500">
                                <div>Digital plan with {quantity} seat/s</div>
                                <div className="pr-6">
                                    $
                                    {currentSubscription?.unit_amount *
                                        quantity}
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <div>Total per month</div>
                                <div className="pr-6">
                                    $
                                    {currentSubscription?.unit_amount *
                                        quantity}
                                </div>
                            </div>
                        </div>

                        <div className="flex gap-4">
                            <button
                                className="bg-jp-blue py-1 px-4 rounded opacity-70 hover:opacity-100"
                                onClick={onSubmit}
                                disabled={submitting}
                            >
                                Update billing amount
                            </button>
                            <button
                                onClick={() => {
                                    history.goBack();
                                }}
                                className="border py-1 px-4 rounded opacity-70 hover:opacity-100"
                            >
                                Cancel
                            </button>
                        </div>
                    </>
                ) : (
                    "You do not have active subscription at the moment."
                )}
            </div>
        </div>
    );
};

export default UpdateQuantity;
