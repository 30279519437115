import React, { useEffect, useState } from "react";
import { getAlbums } from "../../core/services/album.service";
import AlbumItem from "../dashboard/AlbumItem";
import jpGradientBGImage from "./../../../assets/images/jp-gradient.png";

import styles from "../playlists/playlists.module.scss";
import Spinner from "../../core/component/Spinner";
import BackBar from "../../core/component/BackBar";
import RowLoader from "../../core/component/RowLoader";

const AllAlbums = () => {
    const [albums, setAlbums] = useState<any>([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getAlbums({ page: 1, size: 30, order_by: "published_at", dir: "desc" })
            .then((resp: any) => {
                setAlbums(resp.data.albums);
                setLoading(false);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);

    return (
        <div
            style={{ backgroundImage: `url(${jpGradientBGImage})` }}
            className={`bg-contain lg:bg-contain 4xl:bg-cover  bg-top bg-no-repeat -mt-10 md:-mt-16 lg:-mt-24 ${styles.headerImage}`}
        >
            <div
                className={`container p-4 pt-16 lg:p-16 flex items-center ${styles.spacer}`}
            >
                <div className="w-2/3 md:w-1/4 mt-20 md:mt-0">
                    <BackBar />

                    <div className="uppercase text-xl md:text-2xl lg:text-4xl text-white">
                        New
                    </div>
                    <div className="text-gray-400 text-sm md:text-base">
                        Explore the latest content from Josh Phegan
                    </div>
                </div>
            </div>
            <div className="container p-4 pt-4 md:pt-16 lg:p-16 space-y-2 md:space-y-6 w-full min-h-screen">
                {albums.length ? (
                    <>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 md:gap-x-8 lg:gap-x-10 gap-y-12 md:gap-y-16 lg:gap-y-20">
                            {albums.map((album: any) => {
                                return (
                                    <div
                                        key={album.id}
                                        className="aspect-w-16 aspect-h-9"
                                    >
                                        <AlbumItem album={album} />
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <RowLoader />
                )}
            </div>
            {loading ? (
                <div className="flex justify-center py-10">
                    <Spinner />
                </div>
            ) : null}
        </div>
    );
};

export default AllAlbums;
