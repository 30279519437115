import {
    ArrowLeftIcon,
    CheckIcon,
    PlusIcon,
    TrashIcon,
    XIcon,
} from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getAlbumById } from "../../core/services/album.service";
import {
    createQuestion,
    reorder,
    saveAnswers,
    updateQuestion,
} from "../../core/services/quiz.service";

import QuestionList from "./List";
import QuestionTypeIcon from "./QuestionTypeIcon";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { getLoggedInUser } from "../../core/services/auth.service";

type AlbumsParams = {
    id: string;
};
const QuizStudio = () => {
    const { id } = useParams<AlbumsParams>();

    const [album, setAlbum] = useState<any>();

    const [questionType, setQuestionType] = useState("boolean");

    const [question, setQuestion] = useState("");

    const [answers, setAnswers] = useState<any>([]);

    const [refreshAlbumTrigger, setRefreshAlbumTrigger] = useState(0);

    const [editQuestion, setEditQuestion] = useState<any>();

    const history = useHistory();

    useEffect(() => {
        if (editQuestion) {
            setQuestionType(editQuestion.attributes.type);
            setQuestion(editQuestion.attributes.title);
            setAnswers([]);
            setTimeout(() => {
                setAnswers(() => {
                    let localAnswers = editQuestion.relationships.answers.map(
                        (answer: any) => {
                            return {
                                id: answer.id,
                                title: answer.attributes.title,
                            };
                        }
                    );

                    if (
                        editQuestion.attributes.type === "boolean" &&
                        !localAnswers.length
                    ) {
                        localAnswers = [
                            {
                                id: new Date().valueOf(),
                                title: "Yes",
                                is_correct: 0,
                            },
                            {
                                id: new Date().valueOf(),
                                title: "No",
                                is_correct: 0,
                            },
                        ];
                    }

                    return [...localAnswers];
                });
            }, 1000);
        }
    }, [editQuestion]);

    useEffect(() => {
        getAlbumById(id)
            .then((resp) => {
                setAlbum(resp.data);
            })
            .catch((err) => {
                history.push("/");
            });
    }, [refreshAlbumTrigger]);

    useEffect(() => {
        if (questionType === "boolean") {
            setAnswers(() => {
                return [
                    {
                        id: new Date().valueOf(),
                        title: "Yes",
                        is_correct: 0,
                    },
                    {
                        id: new Date().valueOf(),
                        title: "No",
                        is_correct: 1,
                    },
                ];
            });
        } else {
            setAnswers([]);
        }
    }, [questionType]);

    useEffect(() => {
        // getLoggedInUser().then((resp) => {
        //     if (!resp.data.attributes.is_admin) {
        //         history.push("/");
        //     }
        // });
    }, []);

    return (
        <div className="container text-white p-4 lg:p-16 flex flex-col md:flex-row gap-14">
            <div className={"md:w-2/4"}>
                <div className={"flex gap-6 items-center mb-10"}>
                    <div
                        onClick={() => history.goBack()}
                        className="cursor-pointer"
                    >
                        <ArrowLeftIcon className={"w-6"} />
                    </div>

                    <div className={"text-xl"}>Create Quiz</div>

                    <div>
                        {/* <button className={"bg-gray-600 py-1 px-4 rounded"}>
                            Publish
                        </button> */}
                    </div>
                </div>

                <div className={"space-y-4"}>
                    <div className={"space-y-6"}>
                        <div className={"text-lg"}>Questions and Answers</div>
                        <div className={"flex justify-between items-center"}>
                            <div className={"flex gap-2 items-center"}>
                                <div className="">
                                    <QuestionTypeIcon type={questionType} />
                                </div>
                                <div className={"text-gray-400"}>
                                    <select
                                        onChange={(ev) => {
                                            setQuestionType(ev.target.value);
                                        }}
                                        className="w-full bg-transparent border  py-1 pl-2 pr-4 rounded"
                                        value={questionType}
                                    >
                                        <option value="boolean">
                                            True/False
                                        </option>
                                        <option value={"multiple"}>
                                            Multiple Choice
                                        </option>
                                        <option value={"sort"}>Sort</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className={"border p-2 rounded relative"}>
                            <div
                                className={
                                    "absolute -top-2 bg-jp-black text-xs px-1 text-gray-400"
                                }
                            >
                                Type your question
                            </div>
                            <input
                                type="text"
                                className={"bg-transparent w-full outline-none"}
                                onChange={(ev) => {
                                    setQuestion(ev.target.value);
                                }}
                                value={question}
                            />
                        </div>
                        <div className={"space-y-4 pt-4"}>
                            <DragDropContext
                                onDragEnd={(result) => {
                                    if (!result.destination) {
                                        return;
                                    }

                                    const items = reorder(
                                        answers,
                                        result.source.index,
                                        result.destination.index
                                    );

                                    setAnswers([...items]);
                                }}
                            >
                                <Droppable
                                    droppableId="droppable"
                                    isDropDisabled={questionType !== "sort"}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        >
                                            {answers.map(
                                                (
                                                    answer: any,
                                                    index: number
                                                ) => (
                                                    <Draggable
                                                        key={answer.id}
                                                        draggableId={answer.id.toString()}
                                                        index={index}
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                            >
                                                                <div
                                                                    className={
                                                                        "flex justify-between items-center mb-4"
                                                                    }
                                                                    key={
                                                                        answer.id
                                                                    }
                                                                >
                                                                    <div className="flex gap-4">
                                                                        {questionType ===
                                                                        "sort" ? (
                                                                            <div
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <QuestionTypeIcon
                                                                                    type={
                                                                                        questionType
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={
                                                                                    "bg-white text-jp-black px-2 rounded uppercase"
                                                                                }
                                                                            >
                                                                                {String.fromCharCode(
                                                                                    97 +
                                                                                        index
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                        <input
                                                                            type="text"
                                                                            placeholder="Type your answer"
                                                                            className={
                                                                                "bg-transparent w-full outline-none"
                                                                            }
                                                                            defaultValue={
                                                                                answer.title
                                                                            }
                                                                            onChange={(
                                                                                ev
                                                                            ) => {
                                                                                setAnswers(
                                                                                    (
                                                                                        currentState: any
                                                                                    ) => {
                                                                                        currentState[
                                                                                            index
                                                                                        ][
                                                                                            "title"
                                                                                        ] =
                                                                                            ev.target.value;
                                                                                        return [
                                                                                            ...currentState,
                                                                                        ];
                                                                                    }
                                                                                );
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            "flex gap-4 items-center"
                                                                        }
                                                                    >
                                                                        {questionType !==
                                                                             "sort" && (
                                                                            <div
                                                                                className={
                                                                                    "flex gap-2 text-gray-400"
                                                                                }
                                                                            >
                                                                                <button
                                                                                    onClick={(
                                                                                        ev
                                                                                    ) => {
                                                                                        setAnswers(
                                                                                            (
                                                                                                currentState: any
                                                                                            ) => {
                                                                                                currentState[
                                                                                                    index
                                                                                                ][
                                                                                                    "is_correct"
                                                                                                ] =
                                                                                                    true;
                                                                                                return [
                                                                                                    ...currentState,
                                                                                                ];
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <CheckIcon
                                                                                        className={`w-6 ${
                                                                                            answer.is_correct &&
                                                                                            "text-jp-green-300"
                                                                                        }`}
                                                                                    />
                                                                                </button>
                                                                                <button
                                                                                    onClick={(
                                                                                        ev
                                                                                    ) => {
                                                                                        setAnswers(
                                                                                            (
                                                                                                currentState: any
                                                                                            ) => {
                                                                                                currentState[
                                                                                                    index
                                                                                                ][
                                                                                                    "is_correct"
                                                                                                ] =
                                                                                                    false;
                                                                                                return [
                                                                                                    ...currentState,
                                                                                                ];
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                >
                                                                                    <XIcon
                                                                                        className={`w-6 ${
                                                                                            !answer.is_correct &&
                                                                                            "text-jp-green-300"
                                                                                        }`}
                                                                                    />
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                        {questionType !==
                                                                             "boolean" && (
                                                                            <button
                                                                                onClick={(
                                                                                    ev
                                                                                ) => {
                                                                                    setAnswers(
                                                                                        (
                                                                                            currentState: any[]
                                                                                        ) => {
                                                                                            return [
                                                                                                ...currentState.filter(
                                                                                                    (
                                                                                                        x
                                                                                                    ) =>
                                                                                                        x.id !==
                                                                                                        answer.id
                                                                                                ),
                                                                                            ];
                                                                                        }
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <TrashIcon
                                                                                    className={
                                                                                        "w-6"
                                                                                    }
                                                                                />
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>

                            {questionType !== "boolean" && (
                                <div>
                                    <button
                                        className={
                                            "flex justify-center items-center gap-2 bg-jp-blue py-1 px-4 rounded text-sm"
                                        }
                                        onClick={(ev) => {
                                            setAnswers((currentState: any) => {
                                                return [
                                                    ...currentState,
                                                    {
                                                        id: new Date().valueOf(),
                                                        title: "",
                                                        is_correct: false,
                                                    },
                                                ];
                                            });
                                        }}
                                    >
                                        <PlusIcon className={"w-4"} /> Add
                                        Answer
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={"py-6 flex flex-col gap-4"}>
                        <button
                            className={
                                "flex justify-center items-center border border-dotted py-4 w-full rounded gap-4 hover:border-jp-blue hover:text-jp-blue"
                            }
                            onClick={() => {
                                const params = {
                                    title: question,
                                    type: questionType,
                                };

                                const request = editQuestion
                                    ? updateQuestion(
                                          album.id,
                                          editQuestion.id,
                                          params
                                      )
                                    : createQuestion(album.id, params);

                                request.then((resp) => {
                                    let localAnswers = [...answers];

                                    if (questionType === "sort") {
                                        localAnswers = answers.map(
                                            (answer: any, index: 0) => {
                                                delete answer.is_correct;

                                                return {
                                                    ...answer,
                                                    order: index,
                                                };
                                            }
                                        );
                                    }

                                    saveAnswers(
                                        album.id,
                                        resp.data.id,
                                        localAnswers
                                    ).then((resp) => {
                                        toast.success(
                                            "Question stored successfully."
                                        );

                                        setQuestion(() => "");
                                        setAnswers(() => []);

                                        setRefreshAlbumTrigger(
                                            (currentState) => {
                                                return currentState + 1;
                                            }
                                        );
                                    });
                                });
                            }}
                        >
                            {editQuestion ? "Save" : "Submit"} Question
                        </button>
                        {editQuestion && (
                            <button
                                onClick={() => {
                                    window.location.reload();
                                }}
                                className={
                                    "flex justify-center items-center border border-dotted py-4 w-full rounded gap-4 hover:border-red-500 hover:text-red-500"
                                }
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div className={"md:w-2/4"}>
                <QuestionList
                    album={album}
                    setRefreshAlbumTrigger={setRefreshAlbumTrigger}
                    setEditQuestion={setEditQuestion}
                />
            </div>
        </div>
    );
};

export default QuizStudio;
