import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {
    BugsnagErrorBoundary,
} from "@bugsnag/plugin-react";

Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_KEY + "",
    plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary: BugsnagErrorBoundary = (
    Bugsnag.getPlugin("react") as any
).createErrorBoundary(React);

ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
