import { groupBy, map, sumBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { getInvoices } from "../../core/services/auth.service";
import styles from "./index.module.scss";

const Invoices = () => {
    const [invoices, setInvoices] = useState<any>([]);
    useEffect(() => {
        getInvoices().then((resp) => {
            const mappedInvoices = map(
                groupBy(
                    resp.data.map((invoice: any) => {
                        return {
                            ...invoice,
                            key: moment(invoice.attributes.created_at).format(
                                "MMM yyyy"
                            ),
                        };
                    }),
                    (x) => x.key
                ),
                (invoices, date) => {
                    return {
                        date,
                        invoices,
                        sum: sumBy(invoices, (x) => x.attributes.total).toFixed(
                            2
                        ),
                        currency: invoices[0].attributes.currency,
                    };
                }
            );

            setInvoices(mappedInvoices);
        });
    }, []);
    return (
        <div className={"mt-4"}>
            <div>
                <div className={styles.formLabel}>previous invoices</div>
                <div className="space-y-4">
                    <div
                        className={
                            "flex justify-between border-b border-gray-600"
                        }
                    >
                        <div>Description</div>
                        <div>Amount</div>
                    </div>
                    {!invoices.length && <div>No invoices available.</div>}
                    {invoices.map((invoice: any) => {
                        return (
                            <div
                                key={invoice.date}
                                className={
                                    "flex justify-between items-center border-b border-gray-600 pb-2"
                                }
                            >
                                <div className={""}>
                                    <div>
                                        <div>Monthly payment plan</div>
                                        <div className="text-sm text-gray-400">
                                            {invoice.date}
                                        </div>
                                    </div>
                                    {/* <div>
                                        <button
                                            className={"hover:text-jp-blue"}
                                            title="Download"
                                            onClick={() => {
                                                downloadInvoice(
                                                    invoice.id
                                                ).then((resp: any) => {
                                                    const url =
                                                        window.URL.createObjectURL(
                                                            new Blob([resp])
                                                        );
                                                    const link =
                                                        document.createElement(
                                                            "a"
                                                        );
                                                    link.href = url;
                                                    link.setAttribute(
                                                        "download",
                                                        invoice.id + ".pdf"
                                                    );
                                                    document.body.appendChild(
                                                        link
                                                    );
                                                    link.click();
                                                    link.remove();
                                                });
                                            }}
                                        >
                                            <DownloadIcon
                                                className={"w-4 h-4"}
                                            />
                                        </button>
                                    </div> */}
                                </div>
                                <div>
                                    {invoice.currency}
                                    {invoice.sum}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Invoices;
