/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import {
    getAlbumById,
    getAlbumsByCategoryId,
} from "../../core/services/album.service";
import ReactJWPlayer from "react-jw-player";
import { v4 as uuidv4 } from "uuid";
import ContentRow from "./ContentRow";
import { savePlayerProgress } from "../../core/services/player.service";
import { Link } from "react-router-dom";
import { getLoggedInUser } from "../../core/services/auth.service";
import { getQuizResults } from "../../core/services/quiz.service";
import { findIndex, isEmpty } from "lodash";
import BackBar from "../../core/component/BackBar";
import { ReactComponent as DocumentIcon } from "./../../../assets/images/file.svg";
import { ReactComponent as DownloadIcon } from "./../../../assets/images/download.svg";
import ContentLoader from "../../core/component/ContentLoader";
import Bugsnag from "@bugsnag/js";
import { toast } from "react-toastify";
import { useHistory } from "react-router";
import RowLoader from "../../core/component/RowLoader";
import Skeleton from "react-loading-skeleton";

type AlbumsParams = {
    id: string;
};

declare const window: any;

const Albums = () => {
    const { id } = useParams<AlbumsParams>();

    const queryParam = new URLSearchParams(window.location.search);

    const [album, setAlbum] = useState<any>(null);

    const [playlist, setPlaylist] = useState<any[]>([]);

    const jwPlayerRef = useRef();

    const [activeMedia, setActiveMedia] = useState<any>({});

    const [user, setUser] = useState<any>();

    const [quizScore, setQuizScore] = useState<any>();

    const [hasPendingVideo, setHasPendingVideo] = useState(false);

    const [toHome] = useState(queryParam.get("toHome") !== "0");

    const [categoryId] = useState(queryParam.get("categoryId"));

    const [categoryIndex] = useState(queryParam.get("index"));

    const [categoryTracks, setCategoryTracks] = useState([]);

    const [albums, setAlbums] = useState([]);

    const minCategorySize = 10;
    const categoryIndexParsedVal = categoryIndex && parseInt(categoryIndex) + 1;
    const categoryDefSize =
        categoryIndexParsedVal && categoryIndexParsedVal > minCategorySize
            ? categoryIndexParsedVal
            : minCategorySize;

    const [categorySize, setCategorySize] = useState(categoryDefSize);
    const [fetchingPlaylist, setFetchingPlaylist] = useState(false);

    const history = useHistory();
    const setCategoryIndex = (index: number) => {
        let pathname = window.location.pathname;
        history.replace({
            pathname,
            search: `?categoryId=${categoryId}&index=${index}`,
        });
    };

    const fetchData = async () => {
        const { data: localAlbum } = await getAlbumById(id);
        setHasPendingVideo(
            localAlbum.relationships.videos
                .map((x: any) => x.attributes.transcoding_status)
                .includes("pending")
        );
        let categoryTracks: any = [];
        if (categoryId) {
            setPlaylist([]);
            setFetchingPlaylist(true);
            const {
                data: { albums: localAlbums },
            } = await getAlbumsByCategoryId(categoryId, {
                size: categorySize,
                page: 1,
                order_by: "published_at",
                dir: "desc",
            });

            setAlbums(localAlbums);
            categoryTracks = localAlbums
                .map((x: any) => {
                    if (
                        x.relationships.tracks &&
                        x.relationships.tracks?.[0]?.attributes.file_name
                    )
                        return {
                            ...x.relationships.tracks?.[0],
                            image: x.attributes.image,
                            mobile_image: x.attributes.mobile_image,
                            type: x.attributes.type,
                        };
                })
                .flat()
                .filter((el: any) => el !== undefined);

            setCategoryTracks(categoryTracks);
            categoryTracks = categoryTracks.map((track: any) => {
                let file = `https://d3bjw5qhdr0t8c.cloudfront.net/${track.attributes.file_name}/high.m3u8`;
                if (track.type === "audio") {
                    file = `https://s3-ap-southeast-2.amazonaws.com/jpm-audio/high-${track.attributes.file_name}.mp3`;
                }
                return {
                    file: file + "?timestamp=" + uuidv4(),
                    image: track.image,
                    title: track.attributes.title,
                    track,
                };
            });
        }

        setPlaylist(() => {
            if (categoryId) return [...categoryTracks];
            return [
                ...localAlbum.relationships.tracks.map((track: any) => {
                    let file = `https://s3-ap-southeast-2.amazonaws.com/jpm-audio/high-${track.attributes.file_name}.mp3`;
                    if (localAlbum.attributes.type === "video") {
                        file = `https://d3bjw5qhdr0t8c.cloudfront.net/${track.attributes.file_name}/high.m3u8`;
                    }
                    return {
                        file: file + "?timestamp=" + uuidv4(),
                        image: localAlbum.attributes.image,
                        title: track.attributes.title,
                        track,
                    };
                }),
                ...localAlbum.relationships.videos.map((track: any) => {
                    let file: string = track.attributes.playlist || "";

                    if (!file.includes("https://")) {
                        file =
                            "https://jp-portal-files.s3.ap-southeast-2.amazonaws.com/" +
                            file;
                    }

                    return {
                        file: file + "?timestamp=" + uuidv4(),
                        image: track.attributes.image,
                        title: track.attributes.title,
                        track,
                    };
                }),
            ];
        });

        if (localAlbum.relationships.quiz.length) {
            getQuizResults(id).then((resp) => {
                setQuizScore(resp.data.quiz);
            });
        }
        setAlbum(localAlbum);
        setFetchingPlaylist(false);
    };

    useEffect(() => {
        fetchData();
        getLoggedInUser().then((resp) => {
            setUser(resp.data.data);
        });
    }, [id, categorySize]);

    const saveProgress = (is_completed = 0) => {
        savePlayerProgress({
            album_id: id,
            model_type: "track",
            is_completed,
            progress: window.jwplayer().getPosition(),
            model_id: activeMedia.track.id,
        }).then((resp) => {});
    };

    useEffect(() => {
        if (activeMedia) {
            const activeMediaAlbumId = activeMedia?.track?.attributes?.album_id;
            const activeMediaAlbum = albums.find(
                (x: any) => x.id === activeMediaAlbumId
            );
            if (activeMediaAlbum) {
                setAlbum(activeMediaAlbum);
            }

            setTimeout(() => {
                if (activeMedia?.track?.attributes?.latest_progress < 95) {
                    window
                        .jwplayer()
                        .seek(
                            activeMedia?.track?.attributes
                                ?.latest_progress_in_seconds
                        );
                }
            }, 500);
        }

        const interval = setInterval(() => {
            const buffer = window.jwplayer().getBuffer();
            if (activeMedia && activeMedia.track) {
                saveProgress(buffer > 95 ? 1 : 0);
            }
            localStorage.setItem(`album-${id}`, activeMedia?.track?.id);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [activeMedia]);

    useEffect(() => {
        if (!categoryId && playlist.length && jwPlayerRef) {
            const localTrackId = localStorage.getItem(`album-${id}`);
            if (localTrackId) {
                const index = findIndex(
                    playlist,
                    (x) => x.track.id == localTrackId
                );
                if (index > 0) {
                    setTimeout(() => {
                        window.jwplayer().playlistItem(index);
                    }, 500);
                }
            }
        }
    }, [playlist, jwPlayerRef, categoryId]);

    useEffect(() => {
        if (categoryId && !fetchingPlaylist) {
            if (categoryIndex) {
                if (categoryDefSize >= categorySize) {
                    const categoryIndexIntVal = parseInt(categoryIndex || "0");
                    setTimeout(() => {
                        window.jwplayer().playlistItem(categoryIndexIntVal);
                    }, 500);
                } else {
                    const localTrackId = localStorage.getItem(
                        `category-${categoryId}`
                    );
                    if (localTrackId) {
                        const index = findIndex(
                            playlist,
                            (x) => x.track.id == localTrackId
                        );
                        if (index > 0) {
                            setTimeout(() => {
                                window.jwplayer().playlistItem(index);
                            }, 500);
                        }
                    }
                }
            }
        }
    }, [playlist, fetchingPlaylist, categoryId]);

    useEffect(() => {
        if (playlist.length > 0 && categoryId) {
            const index = findIndex(
                playlist,
                (x) => x.track.id == activeMedia?.track?.id
            );
            if (index > 0) {
                localStorage.setItem(
                    `category-${categoryId}`,
                    activeMedia?.track?.id
                );
                setCategoryIndex(index);
            }
        }
    }, [playlist, activeMedia]);

    return (
        <div className="container text-white p-4 lg:p-16">
            <BackBar toHome={toHome} />
            {album ? (
                <div>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 md:gap-8 lg:gap-10 items-start">
                        <div className="order-2 lg:order-1">
                            <div className="">
                                <div className="text-xl md:text-2xl lg:text-4xl font-bold tracking-wide text-center lg:text-left">
                                    {album.attributes.title}
                                </div>
                                <div className="pt-2 md:pt-4 text-sm md:text-base tracking-wide text-center lg:text-left">
                                    {album.attributes.description}
                                </div>

                                {album.relationships.files.length ? (
                                    <div className="p-2 bg-jp-black-900 my-4 w-3/4 text-white">
                                        <div className="text-xl">
                                            Downloadable Resources
                                        </div>
                                        <div className="py-2 space-y-4">
                                            {album.relationships.files.map(
                                                (file: any) => {
                                                    return (
                                                        <div
                                                            className="flex justify-between items-center hover:bg-jp-black-700"
                                                            key={file.id}
                                                        >
                                                            <div className="flex items-center gap-2">
                                                                <div>
                                                                    <DocumentIcon className="w-4 -mt-0.5" />
                                                                </div>
                                                                <div>
                                                                    {
                                                                        file
                                                                            .attributes
                                                                            .title
                                                                    }
                                                                </div>
                                                            </div>
                                                            <a
                                                                href={
                                                                    file
                                                                        .attributes
                                                                        .url
                                                                }
                                                                target={
                                                                    "_blank"
                                                                }
                                                                rel="noopener noreferrer"
                                                            >
                                                                <DownloadIcon className="w-5 " />
                                                            </a>
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                ) : null}

                                {album.relationships?.quiz?.length ? (
                                    <div
                                        className={
                                            "grid grid-cols-1 lg:grid-cols-2 justify-between gap-4 mt-4 items-center"
                                        }
                                    >
                                        {quizScore && (
                                            <div className="text-gray-500 w-full text-center lg:text-left order-3 lg:order-3">
                                                Quiz Score:{" "}
                                                {quizScore?.completion_percent}%
                                            </div>
                                        )}
                                        {user && user.attributes.is_admin && (
                                            <Link
                                                to={`/albums/${id}/quiz-studio`}
                                                className={
                                                    "bg-jp-blue text-white w-full py-1 rounded text-center order-1 lg:order-1"
                                                }
                                            >
                                                Create Quiz
                                            </Link>
                                        )}
                                        {album.relationships.quiz.length ? (
                                            <Link
                                                to={`/albums/${id}/quiz`}
                                                className={
                                                    "bg-jp-blue text-white w-full  py-1 rounded text-center order-2 lg:order-2"
                                                }
                                            >
                                                {quizScore
                                                    ? "Retake the Quiz"
                                                    : "Take Quiz"}
                                            </Link>
                                        ) : null}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="order-1 lg:order-2">
                            {!fetchingPlaylist && playlist.length > 0 ? (
                                <ReactJWPlayer
                                    ref={jwPlayerRef}
                                    playerId={uuidv4()}
                                    playerScript="https://cdn.jwplayer.com/libraries/7SGycuQW.js"
                                    playlist={playlist}
                                    onBuffer={(ev: any) => {
                                        const media = window
                                            .jwplayer()
                                            .getPlaylistItem();
                                        setActiveMedia(media);
                                    }}
                                    isAutoPlay={true}
                                    onError={(err: any) => {
                                        Bugsnag.notify({
                                            ...err,
                                            albumId: id,
                                            album,
                                        });
                                    }}
                                    onSetupError={(err: any) => {
                                        Bugsnag.notify({
                                            ...err,
                                            albumId: id,
                                            album,
                                        });

                                        toast.warning(
                                            <div>
                                                Oops there's an error. You can
                                                watch this directly on the Josh
                                                Phegan App.
                                                <div className="space-x-4">
                                                    <a
                                                        href="https://apps.apple.com/au/app/josh-phegan-digital/id1241201947"
                                                        target={"_blank"}
                                                        className={
                                                            "text-jp-blue"
                                                        }
                                                        rel="noreferrer"
                                                    >
                                                        App Store
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=au.com.joshphegan.joshphegan&hl=en_US&gl=US"
                                                        target={"_blank"}
                                                        className={
                                                            "text-jp-blue"
                                                        }
                                                        rel="noreferrer"
                                                    >
                                                        Google Play
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            ) : (
                                <Skeleton height={400} />
                            )}

                            {hasPendingVideo && isEmpty(activeMedia) ? (
                                <div className="text-small text-gray-400 italic">
                                    There are videos in processing state.
                                </div>
                            ) : null}
                        </div>
                    </div>

                    {activeMedia ? (
                        <>
                            {!fetchingPlaylist ? (
                                <div className="pt-20">
                                    <ContentRow
                                        album={{
                                            ...album,
                                            relationships: {
                                                ...album.relationships,
                                                tracks: categoryTracks.length
                                                    ? categoryTracks
                                                    : album.relationships
                                                          .tracks,
                                            },
                                        }}
                                        activeMedia={activeMedia}
                                        isTeamAlbum={
                                            album.attributes.team_id !== null
                                        }
                                        handleReachEnd={() =>
                                            categoryId &&
                                            setCategorySize(
                                                categorySize + minCategorySize
                                            )
                                        }
                                    />
                                </div>
                            ) : (
                                <div className="pt-20">
                                    <RowLoader />
                                </div>
                            )}
                        </>
                    ) : null}
                </div>
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};

export default Albums;
