import React, { useEffect } from "react";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import NotFound from "./core/component/NotFound";
import ProtectedRoute from "./core/config/protectedRoute";
import Auth from "./modules/auth";
import Dashboard from "./modules/dashboard";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./core/context/auth";
import Albums from "./modules/albums";
import Playlists from "./modules/playlists";
import CallTimer from "./modules/call-timer";
import CallTimerWidget from "./modules/call-timer/CallTimerWidget";
import jpPusher from "./core/helpers/jpPusher";
import Category from "./modules/categories";
import Resources from "./modules/resources";
import AllPlaylists from "./modules/playlists/All";
import AllAlbums from "./modules/albums/All";
import AllCategories from "./modules/categories/All";
import SettingsProtectedRoute from "./core/config/settingsProtectedRoute";
import UserInfo from "./modules/user-info";
import SubscriptionInfo from "./modules/subscription-info";
import { IntercomProvider } from "react-use-intercom";
import TeamManagement from "./modules/team-management";
import QuizStudio from "./modules/quiz-studio";
import Quiz from "./modules/quiz";
import ContentMangement from "./modules/content-management";
import Form from "./modules/content-management/Form";
import UpdateQuantity from "./modules/subscription-info/UpdateQuantity";
import Analytics from "./modules/analytics";
import UserAnalytics from "./modules/analytics/UserAnalytics";
import TeamAnalytics from "./modules/analytics/TeamAnalytics";
import AlbumAnalytics from "./modules/analytics/AlbumAnalytics";
import TeamAlbums from "./modules/albums/TeamAlbums";
import { SkeletonTheme } from "react-loading-skeleton";
import GlobalAlbumsSeeAll from "./modules/analytics/GlobalAlbumsSeeAll";
import ReactGA from "react-ga4";
import TeamAlbumsSeeAll from "./modules/analytics/TeamAlbumsSeeAll";

const INTERCOM_APP_ID = "gcot0shh";
const GA_MEASUREMENT_ID = process.env.REACT_APP_GA_MEASUREMENT_ID || "";

function App() {
    useEffect(() => {
        //TODO::Listen for pusher
        const channel = jpPusher.client.subscribe(jpPusher.pusherChannel);
        channel.bind(jpPusher.pusherEvent, (ev: any) => {
            console.log("🚀 ~ Pusher event", ev);
        });

        ReactGA.initialize(GA_MEASUREMENT_ID);
    }, []);

    return (
        <SkeletonTheme
            baseColor="#212020"
            highlightColor="#999"
            borderRadius={0}
        >
            <IntercomProvider appId={INTERCOM_APP_ID}>
                <AuthProvider>
                    <Router>
                        <div className="min-h-screen">
                            <Switch>
                                <ProtectedRoute
                                    exact
                                    path="/team-albums"
                                    component={TeamAlbums}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/album-analytics/:albumId"
                                    component={AlbumAnalytics}
                                />
                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/global-albums"
                                    component={GlobalAlbumsSeeAll}
                                />
                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/team-albums"
                                    component={TeamAlbumsSeeAll}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/album-analytics/:albumId"
                                    component={AlbumAnalytics}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/user-analytics/:userId"
                                    component={UserAnalytics}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/team-analytics/:teamId"
                                    component={TeamAnalytics}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/analytics"
                                    component={Analytics}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/albums/:id/quiz-studio"
                                    component={QuizStudio}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/call-timer-widget"
                                    component={CallTimerWidget}
                                    header={false}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/albums/:id"
                                    component={Albums}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/team-management"
                                    component={TeamManagement}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/content-management"
                                    component={ContentMangement}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/content-management/form"
                                    component={Form}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/content-management/form/:albumId"
                                    component={Form}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/subscription-info/update-quantity"
                                    component={UpdateQuantity}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/albums/:id/quiz"
                                    component={Quiz}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/user-info"
                                    component={UserInfo}
                                />

                                <SettingsProtectedRoute
                                    exact
                                    path="/settings/subscription-info"
                                    component={SubscriptionInfo}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/categories"
                                    component={AllCategories}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/albums"
                                    component={AllAlbums}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/playlists"
                                    component={AllPlaylists}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/resources"
                                    component={Resources}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/stats-tracker"
                                    component={CallTimer}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/albums/:id"
                                    component={Albums}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/categories/:id"
                                    component={Category}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/playlists/:id"
                                    component={Playlists}
                                />

                                <ProtectedRoute
                                    exact
                                    path="/"
                                    component={Dashboard}
                                />

                                <Route path="/auth" component={Auth} />

                                <Route path="*/*" component={NotFound} />
                            </Switch>
                            <ToastContainer />
                        </div>
                        <div className="mt-10 flex flex-col text-center md:flex-row md:justify-between py-6 px-6 lg:px-20 text-jp-text-500 bg-jp-black-900">
                            <div>
                                &copy; Josh Phegan 2007-
                                {new Date().getFullYear()}
                            </div>
                            <div className="space-x-6">
                                <a href="https://joshphegan.com.au/privacy-policy">Privacy Policy</a>
                                <a href="https://joshphegan.com.au/terms-and-conditions">Terms & Conditions</a>
                            </div>
                        </div>
                    </Router>
                </AuthProvider>
            </IntercomProvider>
        </SkeletonTheme>
    );
}

export default App;
