import moment from "moment";
import React, { useEffect, useState } from "react";
import { getKPIStats } from "../../core/services/callTimer.service";
import styles from "./index.module.scss";
import "moment-timezone";

const CallTimer = () => {
    const [sessionInfo, setSessionInfo] = useState({
        calls: 0,
        connects: 0,
        call_ratio: 0,
        bap: 0,
        map: 0,
        lap: 0,
    });

    const [currentSelection, setCurrentSelection] = useState<
        "day" | "week" | "month" | "year"
    >("day");

    useEffect(() => {
        let to = moment.tz("Australia/Sydney");
        let from = moment(to)
            .subtract(1, currentSelection)
            .tz("Australia/Sydney");

        getKPIStats(from.format("yyyy-MM-DD"), to.format("yyyy-MM-DD"))
            .then((resp) => {
                setSessionInfo(() => {
                    return {
                        calls: resp.data.calls,
                        connects: resp.data.connects,
                        call_ratio: resp.data.calls_ratio,
                        bap: resp.data.appointments.bap,
                        map: resp.data.appointments.map,
                        lap: resp.data.appointments.lap,
                    };
                });
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [currentSelection]);

    return (
        <div className="container px-4 py-16 lg:p-16 flex flex-col items-center justify-center">
            <div className="flex uppercase mb-6 border border-jp-blue">
                <div
                    className={`py-4 px-6  cursor-pointer  text-jp-blue ${
                        currentSelection === "day" && styles.activeSelection
                    }`}
                    onClick={() => {
                        setCurrentSelection("day");
                    }}
                >
                    Today
                </div>
                <div
                    className={`py-4 px-6 border-l border-r border-jp-blue text-jp-blue cursor-pointer  ${
                        currentSelection === "week" && styles.activeSelection
                    }`}
                    onClick={() => {
                        setCurrentSelection("week");
                    }}
                >
                    Week
                </div>
                <div
                    className={`py-4 px-6 border-r border-jp-blue text-jp-blue cursor-pointer  ${
                        currentSelection === "month" && styles.activeSelection
                    }`}
                    onClick={() => {
                        setCurrentSelection("month");
                    }}
                >
                    Month
                </div>
                <div
                    className={`py-4 px-6 text-jp-blue cursor-pointer  ${
                        currentSelection === "year" && styles.activeSelection
                    }`}
                    onClick={() => {
                        setCurrentSelection("year");
                    }}
                >
                    Year
                </div>
            </div>

            <div className={"lg:w-2/5 border border-gray-700 text-white"}>
                <div className={styles["grid-wrapper-stats"]}>
                    <div className={styles["header-col-item"]}>
                        Call sessions
                    </div>
                    <div className={styles["header-col-item"]}>&nbsp;</div>

                    <div>Call sessions</div>

                    <div>{sessionInfo.calls}</div>

                    <div>Connects</div>
                    <div>{sessionInfo.connects}</div>

                    <div>Call Ratio</div>
                    <div>{sessionInfo.call_ratio}%</div>

                    <div className={styles["header-col-item"]}>
                        Appointments
                    </div>
                    <div className={styles["header-col-item"]}>&nbsp;</div>

                    <div>BAP</div>

                    <div>{sessionInfo.bap}</div>

                    <div>MAP</div>
                    <div>{sessionInfo.map}</div>

                    <div>LAP</div>
                    <div>{sessionInfo.lap}</div>
                </div>
                <div>
                    <button
                        className={
                            "w-full py-4 uppercase bg-jp-blue hover:opacity-90 cursor-pointer transition"
                        }
                        onClick={() => {
                            window.open(
                                "/call-timer-widget",
                                "CallTimerWidget",
                                "menubar=0,resizable=0,width=470,height=600"
                            );
                        }}
                    >
                        View Call Timer
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CallTimer;
