import React, { FormEvent, useContext, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../core/component/Spinner";
import { AuthActions, AuthContext } from "../../core/context/auth";
import { loginRequest } from "../../core/services/auth.service";

const Login = () => {
    const { dispatch } = useContext(AuthContext);

    const [formData, setFormData] = useState({
        username: "",
        password: "",
    });

    const [submitting, setSubmitting] = useState(false);

    const onSubmit = (ev: FormEvent) => {
        setSubmitting(true);
        ev.preventDefault();

        loginRequest(formData)
            .then((resp) => {
                dispatch({
                    action: AuthActions.SETTOKEN,
                    data: {
                        token: resp,
                    },
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    return (
        <>
            <h2 className="mb-6 text-3xl font-extrabold text-white">
                Sign in to your account
            </h2>
            <form action="#" className="space-y-6" onSubmit={onSubmit}>
                <div className="space-y-1">
                    <label
                        htmlFor="email"
                        className="block text-base font-thin text-gray-300"
                    >
                        Email address
                    </label>
                    <div className="mt-1">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required
                            className="appearance-none block w-full bg-transparent px-3 py-2 border text-white border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        username: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-1">
                    <label
                        htmlFor="password"
                        className="block text-base font-thin text-gray-300"
                    >
                        Password
                    </label>
                    <div className="mt-1">
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            required
                            className="appearance-none block w-full bg-transparent text-white px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-jp-blue focus:border-jp-blue sm:text-sm"
                            onChange={(ev) => {
                                setFormData((currentState) => {
                                    return {
                                        ...currentState,
                                        password: ev.target.value,
                                    };
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="space-y-2 flex items-center justify-between">
                    <div className="text-sm">
                        <Link
                            to="/auth/password"
                            className="font-medium text-jp-blue hover:text-jp-blue"
                        >
                            Forgot your password?
                        </Link>
                    </div>
                </div>

                <button
                    type="submit"
                    className="w-full flex justify-center py-2  rounded-md text-lg font-light bg-white items-center gap-4"
                    disabled={submitting}
                >
                    Sign in
                    {submitting && <Spinner />}
                </button>
            </form>
            <div className="mt-8">
                <p className="inline text-gray-300 text-base font-light mr-1">
                    {" "}
                    Don't have an account?{" "}
                </p>
                <Link
                    to={"/auth/register"}
                    className="text-jp-blue text-base font-light"
                >
                    Sign up
                </Link>
            </div>
        </>
    );
};

export default Login;
