import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../core/services/category.service";
import placeholderImage from "./../../../assets/images/placeholder.jpg";
import jpGradientBGImage from "./../../../assets/images/jp-gradient.png";

import styles from "../playlists/playlists.module.scss";
import BackBar from "../../core/component/BackBar";
import RowLoader from "../../core/component/RowLoader";

const AllCategories = () => {
    const [categories, setCategories] = useState([]);
    useEffect(() => {
        getCategories({ size: 0 })
            .then((resp: any) => {
                setCategories(resp.data);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, []);

    return (
        <div
            style={{ backgroundImage: `url(${jpGradientBGImage})` }}
            className={`bg-contain lg:bg-contain 4xl:bg-cover  bg-top bg-no-repeat -mt-10 md:-mt-16 lg:-mt-24 ${styles.headerImage}`}
        >
            <div
                className={`container p-4 pt-16 lg:p-16 flex items-center ${styles.spacer}`}
            >
                <div className="w-2/3 md:w-1/4 mt-20 md:mt-0">
                    <BackBar />

                    <div className="uppercase text-xl md:text-2xl lg:text-4xl text-white">
                        Categories
                    </div>
                    <div className="text-gray-400 text-sm md:text-base">
                        Explore all of the categories in the Digital Libary.
                    </div>
                </div>
            </div>
            <div className="container p-4 pt-4 md:pt-16 lg:p-16 space-y-2 md:space-y-6 w-full min-h-screen">
                {categories.length ? (
                    <>
                        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-6 md:gap-x-8 lg:gap-x-10 gap-y-12 md:gap-y-16 lg:gap-y-20">
                            {categories.map((category: any) => {
                                return (
                                    <div
                                        key={category.id}
                                        className="aspect-w-16 aspect-h-9"
                                    >
                                        <Link to={`/categories/${category.id}`}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${
                                                        category.attributes
                                                            .mobile_image ||
                                                        placeholderImage
                                                    }`,
                                                }}
                                                className="h-full w-full bg-no-repeat bg-cover bg-bottom flex flex-row items-center px-10 cursor-pointer rounded"
                                            ></div>
                                            <div className="text-white p-2 h-9 overflow-hidden">
                                                <div className="flex items-center gap-2">
                                                    <div className="text-sm truncate">
                                                        {
                                                            category.attributes
                                                                .title
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })}
                        </div>
                    </>
                ) : (
                    <RowLoader />
                )}
            </div>
        </div>
    );
};

export default AllCategories;
