import Bugsnag from "@bugsnag/js";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
});

httpClient.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const token = JSON.parse(localStorage.getItem("token") || "{}");

        if (token) {
            config.headers = {
                authorization: token
                    ? `${token.token_type} ${token.access_token}`
                    : null,
            };
        }

        if (isEmpty(token)) {
            if (config.url === "/api/v3/users/subscription") {
                throw new Error("No token available.");
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

httpClient.interceptors.response.use(
    (response: AxiosResponse<any>) => {
        // console.log("🚀 ~ response", response);
        return response.data;
    },
    (error) => {
        if (error?.response?.status === 401) {
            localStorage.clear();
            window.location.reload();
            return;
        }

        if (error?.message !== "No token available.") {
            console.log("Notifying to Bugsnag.");
            Bugsnag.notify(error);
            // return;
        }

        if (!error.response) {
            throw error;
        }

        let errorMessage =
            error?.response?.data?.message || "Failed to complete the request";

        if (error?.response?.data?.errors) {
            errorMessage = Object.values(error.response.data.errors).join("\n");

            if (error.response.data.errors.messages) {
                errorMessage = Object.values(
                    error.response.data.errors.messages
                ).join("\n");
            }
        }

        toast.error(errorMessage);

        throw error;
    }
);

export default httpClient;
