/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
    getAlbumsByCategoryId,
    searchAlbums,
} from "../../core/services/album.service";
import { getCategoryById } from "../../core/services/category.service";
import placeholderImage from "./../../../assets/images/placeholder.jpg";
import videoIcon from "./../../../assets/images/video.svg";
import audioIcon from "./../../../assets/images/audio.svg";
import { Link } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/solid";
import { isString, uniqBy } from "lodash";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import BackBar from "../../core/component/BackBar";
import ContentLoader from "../../core/component/ContentLoader";
type CategoryParams = {
    id: string;
};

const escapeCategoryNames = ["Blue-print", "Short Courses", "Special Editions"];

const Category = () => {
    const { id } = useParams<CategoryParams>();

    const [category, setCategory] = useState<any>();

    const [albums, setAlbums] = useState<any>([]);

    const [coverImage, setCoverImage] = useState(placeholderImage);

    const [isReady, setIsReady] = useState(false);

    const [paginationPayload, setPaginationPayload] = useState({
        size: 20,
        page: 1,
        order_by: "published_at",
        dir: "desc",
    });

    const [paginationLoading, setPaginationLoading] = useState(false);

    const [passCategoryId, setPassCategoryId] = useState(false);

    useEffect(() => {
        setPaginationLoading(true);

        getAlbumsByCategoryId(id, paginationPayload)
            .then((resp) => {
                console.log(
                    "🚀 ~ file: index.tsx ~ line 76 ~ .then ~ resp",
                    resp
                );

                setAlbums((currentAlbums: any) => {
                    if (paginationPayload.page === 1) {
                        return uniqBy([...resp.data.albums], (x) => x.id);
                    } else {
                        return uniqBy(
                            [...currentAlbums, ...resp.data.albums],
                            (x) => x.id
                        );
                    }
                });

                if (coverImage === placeholderImage) {
                    const images = resp.data.albums
                        .map((x: any) => x.attributes.image)
                        .filter((y: any) => isString(y));
                    setCoverImage(images[0]);
                }

                setIsReady(true);

                setPaginationLoading(false);
            })
            .catch((err) => {
                console.log("🚀 ~ err", err);
            });
    }, [paginationPayload]);

    useEffect(() => {
        const scrollTracking = () => {
            if (
                window.innerHeight + window.scrollY >=
                document.body.scrollHeight
            ) {
                setPaginationPayload((currentState) => {
                    return {
                        ...currentState,
                        page: currentState.page + 1,
                    };
                });
            }
        };

        if (id) {
            getCategoryById(id)
                .then((resp) => {
                    setCategory(resp.data);
                    const mobileImage = resp.data.attributes.mobile_image;
                    if (mobileImage) {
                        setCoverImage(mobileImage);
                    }

                    const title = resp?.data?.attributes?.title || "";

                    setPassCategoryId(!escapeCategoryNames.includes(title));
                })
                .catch((err) => {
                    console.log("🚀 ~ err", err);
                });
            window.addEventListener("scroll", scrollTracking);
        }

        return () => {
            window.removeEventListener("scroll", scrollTracking);
        };
    }, [id]);

    return (
        <div className="container text-white p-4 lg:p-16">
            <BackBar />

            {category && isReady ? (
                <div>
                    <div className="flex gap-2 md:gap-8 lg:gap-10 items-start">
                        <div className="flex-1">
                            <div className="w-2/3">
                                <div className="text-xl md:text-2xl lg:text-4xl font-bold tracking-wide">
                                    {category?.attributes.title}
                                </div>
                                <div className="pt-2 md:pt-4 text-sm md:text-base tracking-wide">
                                    {category?.attributes.description}
                                </div>
                            </div>
                        </div>

                        <div className="flex-1 flex justify-center">
                            <div className="w-3/5">
                                <div
                                    style={{
                                        backgroundImage: `url(${coverImage}`,
                                    }}
                                    className="w-full aspect-w-16 aspect-h-9 bg-no-repeat bg-cover bg-center rounded"
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-12 md:pt-20">
                        <div className="mb-6 flex">
                            <div className="p-2 bg-jp-blue text-white rounded-l">
                                <SearchIcon className="w-6" />
                            </div>
                            <input
                                type="text"
                                placeholder="Search..."
                                className="p-2 rounded-r  focus:outline-none  text-gray-700"
                                style={{
                                    width: 400,
                                }}
                                onKeyPress={(ev: any) => {
                                    if (ev.key === "Enter") {
                                        const value = ev.target.value;
                                        if (value === "") {
                                            setPaginationPayload(() => {
                                                return {
                                                    size: 10,
                                                    page: 1,
                                                    order_by: "published_at",
                                                    dir: "desc",
                                                };
                                            });
                                            return;
                                        } else {
                                            searchAlbums(value, 10, id)
                                                .then((resp) => {
                                                    setAlbums(() => {
                                                        return [...resp.data];
                                                    });
                                                })
                                                .catch((err) => {
                                                    console.log(
                                                        "🚀 ~ err",
                                                        err
                                                    );
                                                });
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div className="space-y-4">
                            {albums.map((album: any, index: number) => {
                                const categoryIdParam = passCategoryId
                                    ? "&categoryId=" + id + "&index=" + index
                                    : "";
                                return (
                                    <Link
                                        to={`/albums/${album.id}?toHome=0${categoryIdParam}`}
                                        key={album.id}
                                        className="flex gap-4 items-center "
                                    >
                                        <div className="flex-none w-10 h-6">
                                            <img
                                                src={
                                                    album.attributes.type ===
                                                    "video"
                                                        ? videoIcon
                                                        : audioIcon
                                                }
                                                alt="Album Type"
                                            />
                                        </div>
                                        <div className={"w-2/5"}>
                                            {album.attributes.title}
                                        </div>
                                        <div
                                            className={"text-xs text-gray-300"}
                                        >
                                            <SemiCircleProgressBar
                                                percentage={
                                                    album?.attributes.progress
                                                }
                                                showPercentValue
                                                stroke={"#1691D0"}
                                                diameter={80}
                                            />
                                        </div>
                                    </Link>
                                );
                            })}
                        </div>
                        {paginationLoading && (
                            <div className="py-4">Loading...</div>
                        )}
                    </div>
                </div>
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};

export default Category;
