import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { getPlaylistById } from "../../core/services/playlist.service";
import ReactJWPlayer from "react-jw-player";
import { v4 as uuidv4 } from "uuid";
import { findIndex, flattenDeep } from "lodash";
import { savePlayerProgress } from "../../core/services/player.service";
import PlaylistRow from "./PlaylistRow";
import BackBar from "../../core/component/BackBar";
import ContentLoader from "../../core/component/ContentLoader";
import Bugsnag from "@bugsnag/js";
import { toast } from "react-toastify";

type PlaylistsParams = {
    id: string;
};
declare const window: any;

const Playlists = () => {
    const { id } = useParams<PlaylistsParams>();

    const [playlist, setPlaylist] = useState<any>();

    const [tracks, setTracks] = useState<any[]>([]);

    const jwPlayerRef = useRef();

    const [activeMedia, setActiveMedia] = useState<any>({});

    const [isMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        getPlaylistById(id).then((resp) => {
            const tracks = flattenDeep(
                resp.data.relationships.albums.map((x: any) => {
                    const track = x.relationships.tracks.map((y: any) => {
                        return {
                            ...y,
                            image: x.attributes.image,
                            album: x,
                        };
                    });
                    return [...track];
                })
            );

            setPlaylist(() => {
                const playlist = resp.data;
                playlist.relationships["tracks"] = tracks;
                return {
                    ...playlist,
                };
            });

            setTracks((currentState) => {
                return [
                    ...currentState,
                    ...tracks.map((track: any) => {
                        let file = `https://s3-ap-southeast-2.amazonaws.com/jpm-audio/high-${track.attributes.file_name}.mp3`;
                        if (track.album.attributes.type === "video") {
                            file = `https://d3bjw5qhdr0t8c.cloudfront.net/${track.attributes.file_name}/high.m3u8`;
                        }
                        return {
                            file: file + "?timestamp=" + uuidv4(),
                            image: track.image,
                            title: track.attributes.title,
                            track,
                            album: track.album,
                        };
                    }),
                ];
            });
        });
    }, [id]);

    const saveProgress = (is_completed = 0) => {
        savePlayerProgress({
            album_id: activeMedia.album.id,
            model_type: "track",
            is_completed,
            progress: window.jwplayer().getPosition(),
            model_id: activeMedia.track.id,
        }).then((resp) => {});
    };

    useEffect(() => {
        if (activeMedia) {
            setTimeout(() => {
                // console.log(
                //     "🚀 ~ file: index.tsx ~ line 90 ~ setTimeout ~ activeMedia?.track?.attributes",
                //     activeMedia?.track?.attributes
                // );
                if (activeMedia?.track?.attributes?.latest_progress < 95) {
                    window
                        ?.jwplayer()
                        .seek(
                            activeMedia?.track?.attributes?.latest_progress_in_seconds
                        );
                }
            }, 500);
        }

        const interval = setInterval(() => {
            const buffer = window.jwplayer().getBuffer();
            // console.log("🚀 ~ buffer", buffer);
            if (activeMedia && activeMedia.track) {
                saveProgress(buffer > 95 ? 1 : 0);
            }

            localStorage.setItem(`playlist-${id}`, activeMedia?.track?.id);
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [activeMedia]);

    useEffect(() => {
        if (tracks.length && jwPlayerRef) {
            const localTrackId = localStorage.getItem(`playlist-${id}`);

            if (localTrackId) {
                const index = findIndex(
                    tracks,
                    (x: any) => x.track.id == localTrackId
                );
                if (index > 0) {
                    setTimeout(() => {
                        window.jwplayer().playlistItem(index);
                    }, 500);
                }
            }
        }
    }, [playlist, jwPlayerRef]);

    return (
        <div className="container text-white p-4 lg:p-16">
            <BackBar />
            {playlist ? (
                <div>
                    <div
                        className={`${
                            isMobile ? "flex-col" : "flex"
                        } gap-2 md:gap-8 lg:gap-10`}
                    >
                        <div className="flex-1 hidden md:block">
                            <div className="w-full md:w-2/3">
                                <div className="text-xl md:text-2xl lg:text-4xl font-bold tracking-wide">
                                    {playlist.attributes.title}
                                </div>
                                <div className="pt-2 md:pt-4 text-sm md:text-base tracking-wide">
                                    {playlist.attributes.description}
                                </div>
                            </div>
                        </div>

                        <div className="flex-1">
                            {tracks.length > 0 && (
                                <ReactJWPlayer
                                    ref={jwPlayerRef}
                                    playerId={uuidv4()}
                                    playerScript="https://cdn.jwplayer.com/libraries/7SGycuQW.js"
                                    playlist={tracks}
                                    onBuffer={(ev: any) => {
                                        const media = window
                                            .jwplayer()
                                            .getPlaylistItem();
                                        setActiveMedia(media);
                                    }}
                                    isAutoPlay={true}
                                    onError={(err: any) => {
                                        Bugsnag.notify({
                                            ...err,
                                            albumId: id,
                                            activeMedia,
                                        });
                                    }}
                                    onSetupError={(err: any) => {
                                        Bugsnag.notify({
                                            ...err,
                                            albumId: id,
                                            activeMedia,
                                        });

                                        toast.warning(
                                            <div>
                                                Oops there's an error. You can
                                                watch this directly on the Josh
                                                Phegan App.
                                                <div className="space-x-4">
                                                    <a
                                                        href="https://apps.apple.com/au/app/josh-phegan-digital/id1241201947"
                                                        target={"_blank"}
                                                        className={
                                                            "text-jp-blue"
                                                        }
                                                    >
                                                        App Store
                                                    </a>
                                                    <a
                                                        href="https://play.google.com/store/apps/details?id=au.com.joshphegan.joshphegan&hl=en_US&gl=US"
                                                        target={"_blank"}
                                                        className={
                                                            "text-jp-blue"
                                                        }
                                                    >
                                                        Google Play
                                                    </a>
                                                </div>
                                            </div>
                                        );
                                    }}
                                />
                            )}
                        </div>

                        {isMobile && (
                            <div className="flex-1 block md:hidden mt-2">
                                <div className="w-full md:w-2/3">
                                    <div className="text-xl md:text-2xl lg:text-4xl font-bold tracking-wide">
                                        {playlist.attributes.title}
                                    </div>
                                    <div className="pt-2 md:pt-4 text-sm md:text-base tracking-wide">
                                        {playlist.attributes.description}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="pt-20">
                        <PlaylistRow
                            playlist={playlist}
                            activeMedia={activeMedia}
                        />
                    </div>
                </div>
            ) : (
                <ContentLoader />
            )}
        </div>
    );
};

export default Playlists;
