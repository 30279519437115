import React, { useContext, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../core/component/Spinner";
import { AuthContext } from "../../core/context/auth";
import {
    createTeamAlbum,
    getTeamAlbum,
    updateAlbumVideo,
    updateTeamAlbum,
} from "../../core/services/album.service";
import imageIcon from "./../../../assets/images/image.svg";
import lineImage from "./../../../assets/images/line.svg";
import TracksManagement from "./TracksManagement";

const Form = () => {
    const history = useHistory();

    const { params } = useRouteMatch<{ albumId: string }>();

    const [albumId] = useState(params.albumId);

    const { authState } = useContext(AuthContext);

    const [albumPayload, setAlbumPayload] = useState({
        title: "",
        description: "",
    });

    const [albumCover, setAlbumCover] = useState<any>();

    const [tracks, setTracks] = useState<any>([]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (albumId) {
            getTeamAlbum(authState.user.relationships.team.id, albumId).then(
                (resp) => {
                    setAlbumCover(resp.data.attributes.image);
                    setAlbumPayload(() => {
                        return {
                            title: resp.data.attributes.title,
                            description: resp.data.attributes.description,
                        };
                    });
                    setTracks(
                        resp.data.relationships.videos.map((x: any) => {
                            return {
                                id: x.id,
                                title: x.attributes.title,
                                description: x.attributes.description,
                                isEdit: true,
                            };
                        })
                    );
                }
            );
        }
    }, []);

    return (
        <div className="container text-white py-16 pr-10 ">
            <div className="space-y-10">
                <div className="flex gap-6 items-center">
                    <div>
                        <ArrowLeftIcon
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => history.goBack()}
                        />
                    </div>
                    <div className="text-2xl">
                        {albumId ? "Update" : "Create"} album
                    </div>
                    <div className="ml-auto">
                        <button
                            className="py-1 px-4 bg-jp-blue opacity-90 hover:opacity-100 rounded flex gap-4 items-center text-lg"
                            onClick={() => {
                                document.body.classList.add(
                                    "pointer-events-none"
                                );
                                setIsSubmitting(true);
                                let payload: any = {
                                    ...albumPayload,
                                };

                                if (
                                    albumCover &&
                                    typeof albumCover !== "string"
                                ) {
                                    payload = {
                                        ...payload,
                                        image: albumCover,
                                    };
                                }

                                Promise.all([
                                    ...tracks.map((track: any) => {
                                        return updateAlbumVideo(
                                            authState.user.relationships.team
                                                .id,
                                            albumId,
                                            track.id,
                                            {
                                                title: track.title,
                                                description: track.description,
                                            }
                                        );
                                    }),
                                ]).then((resp) => {
                                    (albumId
                                        ? updateTeamAlbum(
                                              authState.user.relationships.team
                                                  .id,
                                              albumId,
                                              payload
                                          )
                                        : createTeamAlbum(
                                              authState.user.relationships.team
                                                  .id,
                                              payload
                                          )
                                    )
                                        .then((resp) => {
                                            toast.success(
                                                "Album details saved successfully."
                                            );
                                            if (!albumId) {
                                                history.push(
                                                    "/settings/content-management/form/" +
                                                        resp.data.id
                                                );
                                                window.location.reload();
                                            }
                                        })
                                        .finally(() => {
                                            setIsSubmitting(false);
                                            document.body.classList.remove(
                                                "pointer-events-none"
                                            );
                                        });
                                });
                            }}
                        >
                            Publish
                            {isSubmitting && <Spinner />}
                        </button>
                    </div>
                </div>

                <div className="flex gap-10">
                    <div className="flex-1 space-y-10">
                        <div className={"border p-2 rounded relative"}>
                            <div
                                className={
                                    "absolute -top-2 bg-jp-black text-xs px-1 text-gray-400"
                                }
                            >
                                Title
                            </div>
                            <input
                                type="text"
                                className={"bg-transparent w-full outline-none"}
                                onChange={(ev) => {
                                    setAlbumPayload((currentState) => {
                                        return {
                                            ...currentState,
                                            title: ev.target.value,
                                        };
                                    });
                                }}
                                placeholder="Title name"
                                defaultValue={albumPayload.title}
                            />
                        </div>

                        <div className={"border p-2 rounded relative"}>
                            <div
                                className={
                                    "absolute -top-2 bg-jp-black text-xs px-1 text-gray-400"
                                }
                            >
                                Description
                            </div>
                            <input
                                type="text"
                                className={"bg-transparent w-full outline-none"}
                                onChange={(ev) => {
                                    setAlbumPayload((currentState) => {
                                        return {
                                            ...currentState,
                                            description: ev.target.value,
                                        };
                                    });
                                }}
                                placeholder="Description"
                                defaultValue={albumPayload.description}
                            />
                        </div>
                    </div>
                    <label className="flex-1 border border-dotted grid place-content-center rounded cursor-pointer">
                        {!albumCover ? (
                            <div className="flex items-center flex-col text-gray-400 text-sm">
                                <img src={imageIcon} className="w-12" />
                                UPLOAD IMAGE
                            </div>
                        ) : (
                            <img
                                src={
                                    typeof albumCover === "string"
                                        ? albumCover
                                        : URL.createObjectURL(albumCover)
                                }
                                alt="Album cover"
                                className="w-full"
                            />
                        )}
                        <input
                            type="file"
                            className="w-0 h-0"
                            accept="images/*"
                            onChange={(ev: any) => {
                                const file = ev.target.files[0];
                                setAlbumCover(file);
                            }}
                        />
                    </label>
                </div>

                <div>
                    <img src={lineImage} className="w-full" />
                </div>

                {albumId ? (
                    <div>
                        <TracksManagement
                            tracks={tracks}
                            setTracks={setTracks}
                            albumId={albumId}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Form;
